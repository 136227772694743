import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Homepage from "./pages/index";
import Dashboard from "./pages/dashboard";
import Exchange from "./pages/exchange";
import Tbi from "./pages/data-tbi";
import Settings from "./pages/settings";
import AccountAffiliate from "./pages/account-affiliate";
import AccountApi from "./pages/account-api";
import AccountDeposit from "./pages/account-deposit";
import AccountOverview from "./pages/account-overview";
import AccountWithdraw from "./pages/account-withdraw";
import BankAcc from "./pages/add-bank-acc";
import DebitCard from "./pages/add-debit-card";
import FundingRate from "./pages/data-funding-rate";
import IndexPrice from "./pages/data-index-price";
import InsuranceFund from "./pages/data-insurance-fund";
import LastPrice from "./pages/data-last-price";
import MarkPrice from "./pages/data-mark-price";
import Demo from "./pages/demo";
import Lock from "./pages/lock";
import Otp1 from "./pages/otp-1";
import Otp2 from "./pages/otp-2";
import Reset from "./pages/reset";
import SettingAccount from "./pages/settings-account";
import Preferences from "./pages/settings-preferences";
import Security from "./pages/settings-security";
import Signin from "./pages/signin";
import Signup from "./pages/signup";
import VerifyStep1 from "./pages/verify-step-1";
import VerifyStep2 from "./pages/verify-step-2";
import VerifyStep3 from "./pages/verify-step-3";
import VerifyStep4 from "./pages/verify-step-4";
import VerifyStep5 from "./pages/verify-step-5";
import VerifyStep6 from "./pages/verify-step-6";
import FarmPage from "./pages/farm";
import { TokenInfo } from "./pages/token-info";
import { QueryClient, QueryClientProvider } from "react-query";
import EmailVerify from "./pages/email-verification";
import { AuthProvider } from "../contexts/AuthContext";
import OnRamp from "./pages/onRamp";
import { ReactQueryDevtools } from "react-query/devtools";
import { WalletContextProvider } from "../contexts/WalletContext";
import { Toaster } from "react-hot-toast";
import { AllNews } from "./pages/allNews";
import { News } from "./pages/news";
import { AboutUs } from "./pages/aboutUs";
import { ProtectedRoute } from "./element/protectedRoute";
import ConfirmPassword from "./pages/cofirmPassword";
import ResetPasswordVerify from "./pages/reset-password-verification";
import IpVerification from "./pages/ip-verification";
import { LocationContextProvider } from "../contexts/LocationContext";
import { CookiePolicy } from "./pages/cookiePolicy";
import { EsignConsent } from "./pages/esignConsent";
import { PrivacyPolicy } from "./pages/privacyPolicy";
import { TermsOfUse } from "./pages/termsOfUse";
import { APIDoc } from "./pages/apiDoc";

const queryClient = new QueryClient();

class Index extends Component {
  render() {
    return (
      <React.Fragment>
        {/* <BrowserRouter basename={'/demo/tradix_react'}> */}
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <LocationContextProvider>
              <AuthProvider>
                <div id="main-wrapper">
                  <Switch>
                    <Route path="/" exact component={Homepage} />
                    {/* <Route path='/dashboard' component={Dashboard} /> */}
                    <Route
                      path="/exchange/:network/:tradingPair"
                      component={Exchange}
                    />
                    <Route path="/on-ramp" component={OnRamp} />
                    <Route path="/farm" component={FarmPage} />
                    <Route path="/token-info" component={TokenInfo} />
                    {/* <Route path='/data-tbi' component={Tbi} /> */}
                    {/* <Route path='/settings' component={Settings} /> */}
                    {/* <Route path='/account-affiliate' component={AccountAffiliate} /> */}
                    {/* <Route path='/account-api' component={AccountApi} /> */}
                    <Route path="/add-bank-acc" com ponent={BankAcc} />
                    <Route path="/add-debit-card" component={DebitCard} />
                    {/* <Route path='/data-funding-rate' component={FundingRate} />
                                        <Route path='/data-index-price' component={IndexPrice} />
                                        <Route path='/data-insurance-fund' component={InsuranceFund} />
                                        <Route path='/data-last-price' component={LastPrice} />
                                        <Route path='/data-mark-price' component={MarkPrice} /> */}
                    {/* <Route path='/demo' component={Demo} /> */}
                    <Route path="/lock" component={Lock} />
                    <Route path="/otp-1" component={Otp1} />
                    <Route path="/otp-2" component={Otp2} />
                    <Route path="/reset" component={Reset} />
                    <Route
                      path="/reset-password/:id/:token"
                      component={ResetPasswordVerify}
                    />
                    <Route
                      path="/verify-me/:id/:token"
                      component={IpVerification}
                    />
                    {/* <Route path='/settings-account' component={SettingAccount} /> */}
                    {/* <Route path='/settings-preferences' component={Preferences} /> */}
                    <Route path="/signin" component={Signin} />
                    <Route path="/signup" component={Signup} />
                    {/* <Route path='/verify-step-1' component={VerifyStep1} /> */}
                    {/* <Route path='/verify-step-2' component={VerifyStep2} /> */}
                    {/* <Route path='/verify-step-3' component={VerifyStep3} />
                                        <Route path='/verify-step-4' component={VerifyStep4} />
                                        <Route path='/verify-step-5' component={VerifyStep5} />
                                        <Route path='/verify-step-6' component={VerifyStep6} /> */}
                    <Route
                      path="/verify-email/:id/:token"
                      component={EmailVerify}
                    />
                    <Route
                      path="/verify-email/:id/:token"
                      component={EmailVerify}
                    />
                    <Route path="/verify-email/:id" component={EmailVerify} />
                    <Route path="/blogs" component={AllNews} />
                    <Route path="/blog/:id" component={News} />
                    <Route path="/aboutus" component={AboutUs} />
                    <Route path="/cookie-policy" component={CookiePolicy} />
                    <Route path="/esign-consent" component={EsignConsent} />
                    <Route path="/privacy-policy" component={PrivacyPolicy} />
                    <Route path="/terms-of-use" component={TermsOfUse} />
                    <Route path="/api-doc" component={APIDoc} />
                    {/* protected routes */}
                    <ProtectedRoute
                      path={`/account-overview`}
                      component={AccountOverview}
                    />
                    <ProtectedRoute path={`/settings`} component={Settings} />
                    <ProtectedRoute
                      path="/confirm-password"
                      component={ConfirmPassword}
                    />
                    <ProtectedRoute
                      path="/settings-security"
                      component={Security}
                    />
                    <ProtectedRoute
                      path="/verify-step-2"
                      component={VerifyStep2}
                    />
                    <ProtectedRoute
                      path="/account-deposit/:id"
                      component={AccountDeposit}
                    />
                    <ProtectedRoute
                      path="/account-deposit"
                      component={AccountDeposit}
                    />
                    <ProtectedRoute
                      path="/account-withdraw/:id"
                      component={AccountWithdraw}
                    />
                    <ProtectedRoute
                      path="/account-withdraw"
                      component={AccountWithdraw}
                    />
                  </Switch>
                </div>
                <ReactQueryDevtools position="bottom-right" />
              </AuthProvider>
            </LocationContextProvider>
          </BrowserRouter>
        </QueryClientProvider>
        <Toaster position="top-right" />
      </React.Fragment>
    );
  }
}

export default Index;
