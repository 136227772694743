import React, { useRef, useState, useContext } from 'react';
import Axios from 'axios';
import { Link, useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { LinearProgress } from '@mui/material';
import ReCAPTCHA from 'react-google-recaptcha';

import { BACKEND_API_URL, HTTP_SUCCESS_STATUS, RECAPTCHA_KEY } from '../../shared/constants';
import { escapeHtmlObject, isPhoneNumber } from '../../shared/helpers';
import { AuthContext } from '../../contexts/AuthContext';
// import { LocationContext } from '../../contexts/LocationContext';

function Signup() {
  const { curerntIp, setCurrentUser } = useContext(AuthContext);

  // const { location, locationLoading } = useContext(LocationContext);

  const history = useHistory();

  const [hasError, setHasError] = useState(false);

  const recaptchaRef = useRef();

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      wallet_addr: '',
      phone_number: '',
      password: '',
      password_confirmation: '',
    },
    initialErrors: {
      name: '',
      email: '',
      wallet_addr: '',
      phone_number: '',
      password: '',
      password_confirmation: '',
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .min(3, 'Username must be longer than 3 characters.')
        .max(30, 'Username must be less than 30 characters')
        .required('Please enter username'),
      wallet_addr: Yup.string()
        .length(42, 'Wallet address must be 42 characters.')
        .required('Please enter wallet address.'),
      phone_number: Yup.string().min(3).max(255).required('Please enter phone number.'),
      email: Yup.string().email('Invalid email address.').required('Please enter email.'),
      password: Yup.string().min(8, 'Password must be longer than 8 characters.').required('Please enter password'),
      password_confirmation: Yup.string()
        .required()
        .oneOf([Yup.ref('password'), null], 'Passwords must be matched'),
    }),
    handleSubmit: async () => { },
    onSubmit: async () => {
      // TODO-block indonesia users from signing up 2 days
      // if (locationLoading || !location || location === 'ID') {
      //   setHasError('We are sorry but service is under maintenance until 8/2/2023 20:00.');
      //   return false;
      // }

      if (!isPhoneNumber(formik.values.phone_number)) {
        formik.setFieldError('phone_number', 'Please input valid phone number.')
        return false;
      }

      setHasError('');
      let response = {};
      const recaptchaValue = recaptchaRef.current.getValue();

      if (!recaptchaValue) {
        return false;
      }

      const payloadObject = {
        ...escapeHtmlObject(formik.values),
        phone_number: isPhoneNumber(formik.values.phone_number),
        ip_address: curerntIp,
        recaptchaToken: recaptchaValue,
      };
      recaptchaRef.current.reset();
      try {
        response = await Axios.post(`${BACKEND_API_URL}/auth/sign-up`, { ...payloadObject });
        if (response.status === HTTP_SUCCESS_STATUS) {
          const { user } = response.data.data;
          setCurrentUser({ ...user, id: user._id });
          history.push(`/verify-email/${response?.data?.data?.user?._id}`);
          return false;
        }
        throw new Error(response?.data.message);
      } catch (e) {
        if (e?.response?.data?.message) {
          console.log('Signup-Page@sign-up-error:', e?.response?.data?.message);
          setHasError(e?.response?.data?.message);
        } else {
          console.error('Signup-Page@sign-up-error:', e?.message);
          setHasError(e?.message);
        }
      }
      return response;
    },
  });

  return (
    <div className="authincation section-padding">
      <div className="container h-100">
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-xl-5 col-md-6">
            <div className="mini-logo text-center my-5">
              <Link to={'./'}>
                <img
                  src={require('../../images/logo.png')}
                  width="300px"
                  alt=""
                />
              </Link>
            </div>
            <div className="auth-form card">
              {formik.isSubmitting && <LinearProgress color="info" />}
              <div className="card-header justify-content-center">
                <h4 className="card-title">Sign up your account</h4>
              </div>
              {hasError && <p className="text-danger my-3 text-center">{hasError}</p>}
              <div className="card-body">
                <form
                  method="post"
                  name="myform"
                  className="signup_validate"
                >
                  <div className="mb-3">
                    <label>Username</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="username"
                      name="name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.name && formik.errors.name ? (
                      <div className="text-danger">{formik.errors.name}</div>
                    ) : (
                      ``
                    )}
                  </div>
                  <div className="mb-3">
                    <label>Email</label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="hello@example.com"
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <div className="text-danger">{formik.errors.email}</div>
                    ) : (
                      ``
                    )}
                  </div>
                  <div className='mb-3'>
                    <label>Phone Number</label>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text ps-4 pe-4">
                          <i className="fa fa-phone"></i>
                        </span>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        name={'phone_number'}
                        value={formik.values.phone_number}
                        placeholder="+1 12365480"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                    {formik.touched.phone_number && formik.errors.phone_number ? (
                      <div className="text-danger">{formik.errors.phone_number}</div>
                    ) : (
                      ``
                    )}
                  </div>
                  <div className="mb-3">
                    <label>Wallet Address</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="0x..."
                      id="wallet_addr"
                      name="wallet_addr"
                      value={formik.values.wallet_addr}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.wallet_addr && formik.errors.wallet_addr ? (
                      <div className="text-danger">{formik.errors.wallet_addr}</div>
                    ) : (
                      ``
                    )}
                  </div>
                  <div className="mb-3">
                    <label>Password</label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Password"
                      name="password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.password && formik.errors.password ? (
                      <div className="text-danger">{formik.errors.password}</div>
                    ) : (
                      ``
                    )}
                  </div>
                  <div className="mb-3">
                    <label>Confirm Password</label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Password"
                      name="password_confirmation"
                      value={formik.values.password_confirmation}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.password_confirmation && formik.errors.password_confirmation ? (
                      <div className="text-danger">{formik.errors.password_confirmation}</div>
                    ) : (
                      ``
                    )}
                  </div>
                  <div className="d-flex justify-content-center">
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey={RECAPTCHA_KEY}
                      onChange={() => { }}
                    />
                  </div>
                  <div className="text-center mt-4">
                    {!formik.isSubmitting ? (
                      <Link
                        to={'#'}
                        className="btn btn-success btn-block"
                        onClick={formik.handleSubmit}
                      >
                        Sign up
                      </Link>
                    ) : (
                      <i className="fa fa-spinner text-primary fa-spin fa-3x fa-fw"></i>
                    )}
                  </div>
                </form>
                <div className="new-account mt-3">
                  <p>
                    Already have an account?{' '}
                    <Link
                      className="text-primary"
                      to={'signin'}
                    >
                      Sign in
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Signup;
