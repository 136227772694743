import { useFormik } from "formik";
import React, { useContext, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import * as Yup from "yup";
import Axios from "axios";
import { LinearProgress } from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";
import jwt_decode from "jwt-decode";
import { toast } from "react-hot-toast";

import {
  BACKEND_API_URL,
  HTTP_CREATED_STATUS,
  // HTTP_FORBID_ERROR_STATUS,
  // HTTP_SUCCESS_CODE,
  HTTP_SUCCESS_STATUS,
  RECAPTCHA_KEY,
} from "../../shared/constants";
import { escapeHtmlObject, setRefreshToken } from "../../shared/helpers";
import { AuthContext } from "../../contexts/AuthContext";

function Signin() {
  const history = useHistory();

  const [hasError, setHasError] = useState(false);

  const recaptchaRef = useRef();

  const { setCurrentUser, setAuthProcessing, setAuthenticated, setAuthToken } =
    useContext(AuthContext);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    initialErrors: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email("Invalid email address.")
        .min(3, "Email should be longer than 3 characters.")
        .max(255, "Email should be shorter than 255 characters.")
        .required("Please enter email."),
      password: Yup.string()
        .min(8, "Password must be longer than 8 characters.")
        .max(30, "Password should be shorter than 30 characters.")
        .required("Please enter password"),
    }),
    handleSubmit: async () => {},
    onSubmit: async () => {
      setHasError("");

      try {
        const recaptchaValue = recaptchaRef.current.getValue();

        if (!recaptchaValue) {
          return false;
        }
        recaptchaRef.current.reset();
        let response = await Axios.post(`${BACKEND_API_URL}/auth/sign-in`, {
          ...escapeHtmlObject(formik.values),
          recaptchaToken: recaptchaValue,
        });

        if (response?.status !== HTTP_SUCCESS_STATUS) {
          if (response?.status == HTTP_CREATED_STATUS) {
            const { id, email, phone_number } = response.data.data;
            setCurrentUser({ id, email, phone_number });
            history.push(`/verify-email/${id}`);
            return false;
          }
          throw new Error(response?.data?.message);
        }

        const { user, accessToken, refreshToken } = response?.data?.data;

        // if 2FA is enabled
        // if (user?.two_fa_enabled) {
        //   setCurrentUser({ ...user, id: user._id });
        //   if (user?.phone_verified_at) {
        //     // send otp verification code
        //     const payload = { ...escapeHtmlObject({ id: user._id, mobile: toString(user.phone_number) }) };

        //     let response = await Axios.post(`${BACKEND_API_URL}/auth/send-otp`, { ...payload });
        //     if (response?.status !== HTTP_SUCCESS_STATUS) {
        //       history.push('/otp-1');
        //       throw new Error('Sending otp code failed.');
        //     }

        //     setAuthProcessing(2);
        //     history.push(`/otp-2`);
        //   } else {
        //     setAuthProcessing(1);
        //     history.push(`/otp-1`);
        //   }
        //   return false;
        // }

        // if 2FA is disabled
        if (
          !response?.data?.data?.accessToken ||
          !response?.data?.data?.refreshToken
        ) {
          throw new Error("No token found. Please try again later.");
        }

        // set auth token in localstorage
        setAuthToken(accessToken);
        setRefreshToken(refreshToken);

        const { id, email, name } = await jwt_decode(accessToken);
        setCurrentUser({ id, email, name });
        setAuthenticated(true);

        history.push(`/exchange/polygon/cax_dai`);
      } catch (e) {
        if (e?.response?.data?.message) {
          console.log(
            "Deposit-Page@deposit-error:",
            e?.response?.data?.message
          );
          toast.error(e?.response?.data?.message);
          setHasError(e?.response?.data?.message);
        } else {
          toast.error(e.message);
          setHasError(e.message);
          console.log(e);
        }
      }

      return false;
    },
  });

  return (
    <>
      <div className="authincation section-padding">
        <div className="container h-100">
          <div className="row justify-content-center h-100 align-items-center">
            <div className="col-xl-5 col-md-6">
              <div className="mini-logo text-center my-5">
                <Link to={"./"}>
                  <img
                    src={require("../../images/logo.png")}
                    alt=""
                    width={`300px`}
                  />
                </Link>
              </div>
              <div className="auth-form card">
                {formik.isSubmitting && <LinearProgress color="info" />}
                <div className="card-header justify-content-center">
                  <h4 className="card-title">Sign in</h4>
                </div>
                {hasError && (
                  <p className="text-danger my-3 text-center">{hasError}</p>
                )}
                <div className="card-body">
                  <form method="post" name="myform" className="signin_validate">
                    <div className="mb-3">
                      <label>Email</label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="hello@example.com"
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.email && formik.errors.email ? (
                        <div className="text-danger">{formik.errors.email}</div>
                      ) : (
                        ``
                      )}
                    </div>
                    <div className="mb-3">
                      <label>Password</label>
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Password"
                        name="password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.password && formik.errors.password ? (
                        <div className="text-danger">
                          {formik.errors.password}
                        </div>
                      ) : (
                        ``
                      )}
                    </div>
                    <div className="row d-flex justify-content-between mt-4 mb-2">
                      <div className="mb-3 mb-0">
                        <label className="toggle">
                          <input className="toggle-checkbox" type="checkbox" />
                          <span className="toggle-switch"></span>
                          <span className="toggle-label">Remember me</span>
                        </label>
                      </div>
                      <div className="mb-3 mb-0">
                        <Link to={"./reset"}>Forgot Password?</Link>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center mb-4">
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey={RECAPTCHA_KEY}
                        onChange={() => {}}
                      />
                    </div>
                    <div className="text-center">
                      {!formik.isSubmitting ? (
                        <Link
                          to={"#"}
                          className="btn btn-success btn-block"
                          onClick={formik.handleSubmit}
                        >
                          Sign in
                        </Link>
                      ) : (
                        <i className="fa fa-spinner text-primary fa-spin fa-3x fa-fw"></i>
                      )}
                    </div>
                  </form>
                  <div className="new-account mt-3">
                    <p>
                      Don't have an account?{" "}
                      <Link className="text-primary" to={"./signup"}>
                        Sign up
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Signin;
