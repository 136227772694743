import { isEmpty, isUndefined, maxBy, min } from "lodash";
import moment from "moment/moment";
import React, { Component, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { Link } from "react-router-dom";
import { formatDegits1 } from "../../shared/helpers";
import { useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { isMobile } from "react-device-detect";

export const getXAxisTime = (data) => {
  const startTime = !isUndefined(data)
    ? new Date(data[0]?.x).getTime()
    : new Date().getTime() + 60000;
  const endTime = !isUndefined(data)
    ? new Date(data[data.length - 1]?.x).getTime()
    : new Date().getTime() + 60000;

  return [startTime, endTime];
};

const AreaChart = ({ data, handleResolution }) => {
  const [options, setOptions] = useState({
    min: getXAxisTime(data)[0] + 60000,
    max: getXAxisTime(data)[1] + 60000,
  });

  const [series, setSeries] = useState(data, options.min, options.max);

  const [selection, setSelection] = useState("all");

  useEffect(() => {
    setSeries(getCompatibleData(data, options.min, options.max));
  }, [data]);

  const updateData = (timeline) => {
    setSelection(timeline);
    handleResolution(timeline);
    switch (timeline) {
      case "one_day":
        setOptions({
          min: moment().subtract(1, "days").valueOf(),
          max: new Date().getTime(),
        });
        break;
      case "one_month":
        setOptions({
          min: moment().subtract(30, "days").valueOf(),
          max: new Date().getTime(),
        });
        break;
      case "six_months":
        setOptions({
          min: moment().subtract(6, "months").valueOf(),
          max: new Date().getTime(),
        });
        break;
      case "one_year":
        setOptions({
          min: moment().subtract(1, "year").valueOf(),
          max: new Date().getTime(),
        });
        break;
      case "ytd":
        setOptions({
          min: moment().startOf("year").valueOf(),
          max: new Date().getTime(),
        });
        break;
      case "all":
        setOptions({
          min: getXAxisTime(data)[0] + 60000,
          max: getXAxisTime(data)[1] + 60000,
        });
        break;
      default:
    }
  };

  return (
    <>
      <div className="d-flex duration-option text-right my-3 px-4">
        <Link
          id="one_day"
          to={`#`}
          onClick={() => updateData("one_day")}
          className={selection === "one_day" ? "active" : ""}
        >
          1D
        </Link>
        &nbsp;
        <Link
          id="one_month"
          to={`#`}
          onClick={() => updateData("one_month")}
          className={selection === "one_month" ? "active" : ""}
        >
          1M
        </Link>
        &nbsp;
        <Link
          id="six_months"
          to={`#`}
          onClick={() => updateData("six_months")}
          className={selection === "six_months" ? "active" : ""}
        >
          6M
        </Link>
        &nbsp;
        <Link
          id="one_year"
          to={`#`}
          onClick={() => updateData("one_year")}
          className={selection === "one_year" ? "active" : ""}
        >
          1Y
        </Link>
        &nbsp;
        <Link
          id="ytd"
          to={`#`}
          onClick={() => updateData("ytd")}
          className={selection === "ytd" ? "active" : ""}
        >
          YTD
        </Link>
        &nbsp;
        <Link
          id="all"
          to={`#`}
          onClick={() => updateData("all")}
          className={selection === "all" ? "active" : ""}
        >
          ALL
        </Link>
      </div>

      <div id="chart-timeline">
        {!isUndefined(data) && !isEmpty(data) ? (
          <LineChart
            width={isMobile ? 400 : 800}
            height={isMobile ? 250 : 500}
            data={series}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
            <XAxis
              dataKey="t"
              minTickGap={30} // Margin between each text
              tickMargin={8} // Padding between text and chart
              tickSize={0} // Line between text and chart
              height={24} // Space including padding
              tickFormatter={(t) =>
                moment(t).format(
                  data.length > 30 ? "MM/DD/YYYY H:m:s" : "M/dd h:m:s"
                )
              }
            />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey="Price"
              stroke="#28e2e2"
              activeDot={{ r: 4 }}
              strokeWidth={3}
              dot={false}
            />
            <Line
              type="monotone"
              dataKey="Sell Price"
              stroke="red"
              strokeWidth={1.5}
              activeDot={{ r: 2 }}
              dot={false}
            />
            <Line
              type="monotone"
              dataKey="Buy Price"
              stroke="green"
              strokeWidth={1.5}
              activeDot={{ r: 2 }}
              dot={false}
            />
          </LineChart>
        ) : (
          <div
            style={{ height: "350px" }}
            className="d-flex justify-content-center align-items-center"
          >
            Price data is unavailable.
          </div>
        )}
      </div>
    </>
  );
};

export default AreaChart;

const getCompatibleData = (_tradingViewData, _min, _max) => {
  let compatibleData = [];

  _tradingViewData.map((data, index) => {
    const xData = new Date(data.x).toDateString();
    // if (_min < xData && _max > xData)
    compatibleData.push({
      t: xData,
      Price: formatDegits1(data.y[0], 6),
      "Sell Price": formatDegits1(data.y[1], 6),
      "Buy Price": formatDegits1(data.y[2], 6),
    });
  });
  // console.log(compatibleData);
  return compatibleData;
};
