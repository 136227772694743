import React from 'react';
import './scss/main.scss';
import Index from './jsx';
import { RecoilRoot } from 'recoil';
import { Web3ReactProvider } from '@web3-react/core';
import Web3 from 'web3';
import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum';
import { Web3Modal } from '@web3modal/react';
import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import { mainnet, polygon, bsc } from 'wagmi/chains';
import { REACT_APP_WC_PROJECT_ID, MOONPAY_PUB_KEY, MOONPAY_ENV } from './shared/constants';
import { MoonPayProvider } from '@moonpay/moonpay-react';

const chains = [mainnet, polygon, bsc];
const projectId = REACT_APP_WC_PROJECT_ID;
const { publicClient } = configureChains(chains, [w3mProvider({ projectId })]);
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, chains }),
  publicClient,
});
const ethereumClient = new EthereumClient(wagmiConfig, chains);

function App() {
  function getLibrary(provider) {
    return new Web3(provider);
  }
  return (
    <div className="App">
      <MoonPayProvider
        apiKey={MOONPAY_PUB_KEY}
        environment={MOONPAY_ENV}
        debug
      >
        <RecoilRoot>
          <WagmiConfig config={wagmiConfig}>
            <Web3ReactProvider getLibrary={getLibrary}>
              <Index />
            </Web3ReactProvider>
          </WagmiConfig>
          <Web3Modal
            projectId={projectId}
            ethereumClient={ethereumClient}
          />
        </RecoilRoot>
      </MoonPayProvider>
    </div>
  );
}

export default App;
