import React, { useContext, useEffect, useRef, useState } from 'react';
import PageTitle from '../element/page-title';
import AccountSubmenu from '../layout/account-submenu';
import Footer2 from '../layout/footer2';
// import { Link } from 'react-router-dom';
// import { Row, Col, Card } from 'react-bootstrap';
import Header2 from '../layout/header2';
import Sidebar from '../layout/sidebar';
import { useRecoilValue } from 'recoil';
import { atomWalletCaladexBalance } from '../../state/atoms';
import { useAccountInfo, useWalletCaladexBalance } from '../../state/hooks';
import { useHistory, useParams } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import { isEmpty, isUndefined } from 'lodash';
import { CircularProgress } from '@mui/material';
import { BACKEND_API_URL, BACKEND_FILE_URL, CHAIN_LIST, DEFAULT_TOKEN_SYMBOL, HTTP_SUCCESS_STATUS } from '../../shared/constants';
import { formatDegits } from '../../shared/helpers';
import { ImageContainer } from './exchange';
import { toast } from 'react-hot-toast';
import Web3 from 'web3';
import Axios from 'axios';
import { useAccount, useFeeData, useNetwork, useSwitchNetwork } from 'wagmi';

function AccountWithdraw() {
  const { id } = useParams();

  const history = useHistory();

  const { currentUser, authToken, isAuthenticated } = useContext(AuthContext);

  const { chain, chains } = useNetwork();

  const { isConnected, address } = useAccount();

  const caladexBalances = useRecoilValue(atomWalletCaladexBalance);

  const { refetch: caladexBalanceRefetch } = useWalletCaladexBalance(authToken, currentUser?.id);

  const { data, refetch: userInfoRefetch } = useAccountInfo(authToken, currentUser?.id);

  const [isWithdrawing, setIsWithdrawing] = useState(false);

  const [destAddress, setDestAddress] = useState(currentUser?.wallet_addr);
  const [withdrawAmount, setWithdrawAmount] = useState(0);
  const { data: feeData, refetch: feeRefetch, isFetched } = useFeeData();
  const { isLoading, switchNetwork } = useSwitchNetwork()

  const addressInputRef = useRef();
  const withdrawAmountInputRef = useRef();

  useEffect(() => {
    caladexBalanceRefetch();
  }, []);

  useEffect(() => {
    if (!isEmpty(caladexBalances) && switchNetwork) {
      const chainId = getTokenCaladexBalance().token_id.chain_id?.toString(10)
      switchNetwork?.(chainId)
    }
  }, [caladexBalances, switchNetwork])

  useEffect(() => {
    if (!isUndefined(caladexBalances) && isConnected) {
      feeRefetch();
    }
  }, [isConnected, chain?.id]);

  const handleWithdraw = async () => {
    // authetication check
    if (!isAuthenticated || !currentUser || !authToken) {
      toast.error('Please sign in to depsit token.');
      return false;
    }

    // validation for input value

    if (!destAddress || !Web3.utils.isAddress(destAddress)) {
      toast.error('Please input valid wallet address!');
      addressInputRef.current.focus();
      return false;
    }

    if (
      !+withdrawAmount ||
      !+getTokenCaladexBalance()?.caladex_balance ||
      withdrawAmount > +getTokenCaladexBalance()?.caladex_balance
    ) {
      toast.error('Please check token balance or input correct token amount.');
      withdrawAmountInputRef.current.focus();
      return false;
    }

    // submit withdraw

    const _toast = toast.loading('Applying Withdrawal...');
    setIsWithdrawing(true);

    try {
      // send server request

      const response = await Axios.post(
        `${BACKEND_API_URL}/balance/withdraw`,
        {
          user_id: currentUser?.id,
          amount: withdrawAmount,
          address: destAddress,
          token_id: getTokenCaladexBalance()?.token_id?._id,
        },
        { headers: { Authorization: `Bearer ${authToken}` } }
      );

      if (response.status !== HTTP_SUCCESS_STATUS) {
        throw new Error(response?.data?.message);
      }

      toast.dismiss(_toast);
      toast.success('Withdrawal Request Successfully Applied!');
      userInfoRefetch();

      setTimeout(() => {
        history.push('/account-overview');
      }, 1000);
    } catch (e) {
      console.log('withdraw-error:', e);
      toast.dismiss(_toast);
      if (e?.response?.data?.message) {
        console.log('Withdraw-Page@withdraw-error:', e?.response?.data?.message);
        toast.error(e?.response?.data?.message);
      } else {
        console.error('Withdraw-Page@withdraw-error:', e.message);
        toast.error(e.message);
      }
    }

    setIsWithdrawing(false);
    return false;
  };

  const getTokenCaladexBalance = () => {
    if (isUndefined(id)) {
      const balance = caladexBalances.find(
        (balance) => balance.token_id.symbol == DEFAULT_TOKEN_SYMBOL && balance.token_id.chain_id == CHAIN_LIST[1]?.chainId
      );

      return balance;
    }

    const balance = caladexBalances.find((balance) => balance.token_id._id == id);
    
    return balance;
  };

  return (
    <>
      <Header2 activePage={`account-overview`} />
      <PageTitle />

      <div className="content-body">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="card sub-menu">
                <div className="card-body">
                  <AccountSubmenu
                    active={`withdraw`}
                    data={data}
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Withdraw</h4>
                  <h4 className="text-white">
                    {!isEmpty(caladexBalances) ? getTokenCaladexBalance()?.token_id?.symbol : ` - `} (
                    {!isEmpty(caladexBalances)
                      ? CHAIN_LIST.find((chain) => chain.chainId == getTokenCaladexBalance()?.token_id?.chain_id)
                        ?.chainName
                      : ` - `}
                    )
                  </h4>
                </div>
                <div className="card-body">
                  <div className="row justify-content-center">
                    <div className="col-xl-8">
                      <form
                        action="javascript:void(0)"
                        className="py-5"
                      >
                        <div className="mb-3 row align-items-center">
                          <div className="col-sm-4">
                            <label
                              for="inputEmail3"
                              className="col-form-label"
                            >
                              Destination Address
                              <br />
                              <small className="text-danger">Please double check this address</small>
                            </label>
                          </div>
                          <div className="col-sm-8">
                            <div className="input-group mb-3">
                              <input
                                type="text"
                                className="form-control text-end"
                                value={destAddress}
                                onChange={(e) => {
                                  setDestAddress(e.target.value);
                                }}
                                ref={addressInputRef}
                                placeholder="0x..."
                              />
                            </div>
                          </div>
                        </div>

                        <div className="mb-3 row align-items-center">
                          <div className="col-sm-4">
                            <label
                              for="inputEmail3"
                              className="col-form-label"
                            >
                              {!isEmpty(caladexBalances) ? (
                                getTokenCaladexBalance()?.token_id.symbol
                              ) : (
                                <CircularProgress sx={{ width: '1rem !important ', height: '1rem !important' }} />
                              )}{' '}
                              Amount
                              <br />
                              <small className="text-primary">
                                Maximum amount withdrawable:{' '}
                                {!isEmpty(caladexBalances) ? (
                                  formatDegits(getTokenCaladexBalance()?.caladex_balance ?? 0, 2)
                                ) : (
                                  <CircularProgress sx={{ width: '1rem !important ', height: '1rem !important' }} />
                                )}{' '}
                                {!isEmpty(caladexBalances) ? getTokenCaladexBalance()?.token_id.symbol : <CircularProgress sx={{ width: '1rem !important ', height: '1rem !important' }} />}
                              </small>
                            </label>
                          </div>
                          <div className="col-sm-8">
                            <div className="input-group mb-3">
                              <div className="input-group-prepend">
                                <label className="input-group-text bg-primary">
                                  <i className="cc text-white">
                                    <ImageContainer
                                      src={`${BACKEND_FILE_URL}/${getTokenCaladexBalance()?.token_id?.logo_url}`}
                                      style={{ width: '30px' }}
                                      alt={!isEmpty(caladexBalances) ? getTokenCaladexBalance()?.token_id.symbol : ``}
                                    />
                                  </i>
                                </label>
                              </div>
                              <input
                                type="text"
                                className={`form-control text-end ${getTokenCaladexBalance()?.caladex_balance < withdrawAmount ? `is-invalid` : ``
                                  }`}
                                value={withdrawAmount}
                                onChange={(e) => {
                                  setWithdrawAmount(e.target.value);
                                }}
                                ref={withdrawAmountInputRef}
                                placeholder=""
                              />
                              <div className="input-group-prepend">
                                <label
                                  className="input-group-text bg-primary text-white cursor-pointer"
                                  onClick={() => {
                                    setWithdrawAmount(getTokenCaladexBalance()?.caladex_balance ?? 0);
                                  }}
                                >
                                  <i>Max</i>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="mb-3 row align-items-center">
                          <div className="col-sm-6">
                            <label
                              for="inputEmail3"
                              className="col-form-label"
                            >
                              {isConnected && !isEmpty(caladexBalances) ? (
                                chain?.name
                              ) : (
                                <CircularProgress sx={{ width: '1rem !important ', height: '1rem !important' }} />
                              )}{' '}
                              Network Fee (
                              {isConnected && !isEmpty(caladexBalances) ? (
                                chain?.nativeCurrency.symbol
                              ) : (
                                <CircularProgress sx={{ width: '1rem !important ', height: '1rem !important' }} />
                              )}
                              )
                              <br />
                              <small>Transactions on the network are priorirized by fees</small>
                            </label>
                          </div>
                          <div className="col-sm-6">
                            {isConnected &&
                              isFetched &&
                              formatDegits(Web3.utils.fromWei(feeData.gasPrice?.toString(), 'gwei'), 2)}
                            Gwei
                          </div>
                        </div>

                        <div className="text-end">
                          <button
                            className="btn btn-primary"
                            disabled={getTokenCaladexBalance()?.caladex_balance < withdrawAmount || isWithdrawing}
                            onClick={handleWithdraw}
                          >
                            Withdraw Now
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Important Information</h4>
                </div>
                <div className="card-body">
                  <div className="important-info">
                    <ul>
                      <li>
                        <i className="mdi mdi-checkbox-blank-circle"></i>
                        For withdrawal, you have to verify all necessary informations.
                      </li>
                      <li>
                        <i className="mdi mdi-checkbox-blank-circle"></i>
                        For security reasons, Caladex process withdrawals by review once a day. For more information in
                        this policy. Please see our wallet security page.
                      </li>
                      <li>
                        <i className="mdi mdi-checkbox-blank-circle"></i>
                        Submit your withdrawals by 07:00 UTC +00 (about 11 hour) to be included in the days batch
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer2 />
    </>
  );
}

export default AccountWithdraw;
