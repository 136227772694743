import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { isEmpty, isUndefined } from "lodash";
import moment from "moment";
import { useRecoilValue } from "recoil";
import { toast } from "react-hot-toast";
import Axios from "axios";
import { useAccount } from "wagmi";

import PageTitle from "../element/page-title";
import AccountSubmenu from "../layout/account-submenu";
import Footer2 from "../layout/footer2";
import Header2 from "../layout/header2";
import { AuthContext } from "../../contexts/AuthContext";
import {
  useAccountInfo,
  useMyInfo,
  useWalletCaladexBalance,
  useWalletTokenBalance,
} from "../../state/hooks";
import {
  atomTargetTokenList,
  atomTokenPairList,
  atomTokenPriceList,
  atomWalletCaladexBalance,
  atomWalletTokenBalance,
} from "../../state/atoms";
import { formatDegits } from "../../shared/helpers";
import { ImageContainer } from "./exchange";
import {
  BACKEND_API_URL,
  BACKEND_FILE_URL,
  CHAIN_LIST,
  ORDER_SIDE,
  TOKEN_DIGITS,
} from "../../shared/constants";
import UserAvatar from "../../images/profile/unknown.png";
import { humanReadableAccount } from "../../shared/web3";

function AccountOverview() {
  const {
    currentUser,
    authToken,
    tokenPairRefresh,
    tokenPriceRefresh,
    targetTokenRefresh,
  } = useContext(AuthContext);

  const { isConnected, address } = useAccount();

  const [isWalletBalanceFetching, setWalletBalanceFetching] = useState(true);
  const [isTokenPairFetching, setIsTokenPairFetching] = useState(false);
  const [isTokenPriceFetching, setIsTokenPriceFetching] = useState(false);
  const [isInitialized, setInitialized] = useState(false);
  const [isRemovingWithdraw, setIsRemovingWithdraw] = useState(false);

  const tokenPairList = useRecoilValue(atomTokenPairList);
  const targetTokenList = useRecoilValue(atomTargetTokenList);
  const caladexBalances = useRecoilValue(atomWalletCaladexBalance);
  const walletBalances = useRecoilValue(atomWalletTokenBalance);
  const tokenPriceList = useRecoilValue(atomTokenPriceList);

  const { data: myData } = useMyInfo(authToken, currentUser?.id);

  const { data, refetch: userInfoRefetch } = useAccountInfo(
    authToken,
    currentUser?.id
  );

  const {
    isFetched: isCaladexFetched,
    isRefetching: isCaladexBalanceRefetching,
    refetch: caladexBalanceRefetch,
  } = useWalletCaladexBalance(authToken, currentUser?.id);
  const {
    isRefetching: isWalletBalanceRefetching,
    refetch: walletBalanceRefetch,
  } = useWalletTokenBalance();

  useEffect(() => {
    setIsTokenPairFetching(true);
    // setWalletBalanceFetching(true)
    setIsTokenPriceFetching(true);

    tokenPairRefresh().then(async () => {
      setIsTokenPairFetching(false);
      await targetTokenRefresh();
      setInitialized(true);
    });
    setIsTokenPairFetching(false);
    userInfoRefetch();
  }, []);

  useEffect(() => {
    if (isInitialized) {
      setWalletBalanceFetching(true);
      caladexBalanceRefetch().then(() => {
        walletBalanceRefetch().then(() => {
          tokenPriceRefresh().then(() => {
            setIsTokenPriceFetching(false);
          });
        });
      });
      setWalletBalanceFetching(false);
    }
  }, [isConnected, address, isInitialized]);

  const handleRemoveWithdrawal = async (id) => {
    const _toast = toast.loading("Removing withdrawal application...");
    setIsRemovingWithdraw(true);

    try {
      await Axios.post(
        `${BACKEND_API_URL}/balance/remove-withdraw`,
        { user_id: currentUser?.id, id },
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
      toast.dismiss(_toast);
      toast.success("Withdrawal Application Successfully Removed!");
    } catch (e) {
      toast.dismiss(_toast);
      if (e?.response?.data?.message) {
        console.log(
          "Account-Overview-Page@remove-tx-error:",
          e?.response?.data?.message
        );
        toast.error(e?.response?.data?.message);
      } else {
        console.error("Account-Overview-Page@remove-tx-error:", e.message);
        toast.error(e.message);
      }
    } finally {
      caladexBalanceRefetch();
      userInfoRefetch();
    }

    return false;
  };

  /**
   *
   * @param {String} _token_id
   * @param {Array<Object>} _balances
   * @returns get wallet balance for one token
   */
  const getTokenBalance = (_token_id, _balances) => {
    const _balance =
      _balances?.find(
        (value) => _token_id == value?.token_id || _token_id == value?._id
      )?.balance ?? 0;

    return +_balance;
  };

  /**
   *
   * @param {Object} _balance
   * @returns get total usd value for one token
   */
  const _getTokenUsdValue = (_balance) => {
    const _wallet_balance =
      walletBalances?.find((value) => _balance.token_id._id === value.token_id)
        ?.balance ?? 0;
    const usdPrice =
      tokenPriceList.find((value) => _balance.token_id.symbol == value.symbol)
        ?.usdPrice ?? 0;
    const totalBalance =
      +usdPrice *
      (+_balance.order_balance + +_balance.caladex_balance + +_wallet_balance);
    const walletBalance = +usdPrice * +_wallet_balance;
    const caladexBalance =
      +usdPrice * (+_balance.order_balance + +_balance.caladex_balance);

    return { totalBalance, walletBalance, caladexBalance };
  };

  /**
   *
   * @param {Array<Object>} _balances
   * @returns get total usd balance in caladex and wallet
   */
  const getTotalUsdBalance = (_balances) => {
    let totalBalance = 0;
    let walletBalance = 0;
    let caladexBalance = 0;
    _balances.map((balance) => {
      totalBalance += _getTokenUsdValue(balance).totalBalance;
      walletBalance += _getTokenUsdValue(balance).walletBalance;
      caladexBalance += _getTokenUsdValue(balance).caladexBalance;
    });
    return { totalBalance, walletBalance, caladexBalance };
  };

  const getMonthlyTradeUsdValue = () => {
    let totalUsdValue = 0;
    let buyUsdValue = 0;
    let sellUsdValue = 0;

    data.monthlyOrders.map((_order) => {
      const tradedAmount = _order.amount - _order.remain_amount;
      const usdPrice =
        tokenPriceList.find((value) => _order.token_id == value.token_id)
          ?.usdPrice ?? 0;
      totalUsdValue += +tradedAmount * +usdPrice;
      _order.type == ORDER_SIDE.BUY
        ? (buyUsdValue += +tradedAmount * +usdPrice)
        : (sellUsdValue += +tradedAmount * +usdPrice);
    });

    data.monthlyTrades.map((_trade) => {
      const tradedAmount = _trade.amount;
      const usdPrice =
        tokenPriceList.find((value) => _trade.token_id == value.token_id)
          ?.usdPrice ?? 0;

      totalUsdValue += +tradedAmount * +usdPrice;
      _trade.type == ORDER_SIDE.BUY
        ? (buyUsdValue += +tradedAmount * +usdPrice)
        : (sellUsdValue += +tradedAmount * +usdPrice);
    });

    return { totalUsdValue, buyUsdValue, sellUsdValue };
  };

  const getTodayLog = () => {
    const userLogs = myData?.userLogs?.filter(
      (value) =>
        moment(value.created_at).valueOf() >
        moment().subtract(1, "days").valueOf()
    );

    return userLogs.length;
  };

  const getUniqueTokens = () => {
    const utillityTokenList = [];
    tokenPairList.map((value, index, array) => {
      if (
        utillityTokenList.find((token) => token.token_id === value.token_id)
      ) {
        return false;
      }
      utillityTokenList.push(value);
      return true;
    });

    return utillityTokenList;
  };

  const emptyBalanceTokens = () => {
    let emptyTokens = [];

    [...getUniqueTokens(), ...targetTokenList].map((token) => {
      const balance = caladexBalances.find(
        (_balance) =>
          _balance?.token_id?._id == token?.token_id ||
          _balance?.token_id?._id == token?._id
      );
      if (isUndefined(balance)) {
        emptyTokens.push({
          caladex_balance: 0,
          order_balance: 0,
          token_id: { ...token, _id: token?.token_id ?? token?._id },
        });
      }
    });

    return emptyTokens;
  };

  return (
    <>
      <Header2 activePage={`account-overview`} />
      <PageTitle
        username={
          !isUndefined(myData) && myData?.user?.name
            ? myData?.user?.name
            : ` - `
        }
      />
      <div className="content-body">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="card sub-menu">
                <div className="card-body">
                  <AccountSubmenu active={`overview`} data={myData} />
                </div>
              </div>
            </div>

            <div className="col-xl-6 col-lg-6 col-md-6">
              <div className="card profile_card">
                <div className="card-body">
                  <div className="d-flex">
                    {!isUndefined(myData) && myData?.user?.avatar ? (
                      <ImageContainer
                        className="me-3 rounded-circle me-0 me-sm-3"
                        src={`${BACKEND_FILE_URL}/${myData?.user?.avatar}`}
                        width="60"
                        height="60"
                        alt=""
                      />
                    ) : (
                      <ImageContainer
                        src={UserAvatar}
                        className="me-3 rounded-circle me-0 me-sm-3"
                        width="60"
                        height="60"
                        alt=""
                      />
                    )}
                    <div className="media-body">
                      <span>Hello</span>
                      <h4 className="mb-2">
                        {!isUndefined(myData) && myData?.user?.name}
                      </h4>
                      <p className="mb-1">
                        {" "}
                        <span>
                          <i
                            className={`fa fa-phone me-2 ${
                              myData?.user?.phone_verified_at
                                ? `text-primary`
                                : `text-danger`
                            }`}
                          ></i>
                        </span>{" "}
                        +
                        {!isUndefined(myData) && myData?.user?.phone_number
                          ? myData?.user?.phone_number
                          : ` - `}
                      </p>
                      <p className="mb-1">
                        {" "}
                        <span>
                          <i
                            className={`fa fa-envelope me-2 ${
                              myData?.user?.email_verified_at
                                ? `text-primary`
                                : `text-danger`
                            }`}
                          ></i>
                        </span>
                        {!isUndefined(myData) && myData?.user?.email}
                      </p>
                    </div>
                  </div>

                  <ul className="card-profile__info">
                    <li>
                      <h5 className="me-4">Address</h5>
                      <span className="text-muted">
                        {!isUndefined(myData) && myData?.user?.address
                          ? myData?.user?.address
                          : ` - `}
                        ,
                        {!isUndefined(myData) && myData?.user?.city
                          ? myData?.user?.city
                          : ` - `}
                        ,
                        {!isUndefined(myData) && myData?.user?.country
                          ? myData?.user?.country
                          : ` - `}
                      </span>
                    </li>
                    <li className="mb-1">
                      <h5 className="me-4">Total Log</h5>
                      <span>
                        {!isUndefined(myData)
                          ? myData?.userLogs?.length
                          : ` - `}{" "}
                        Times (Today{" "}
                        {!isUndefined(myData) ? getTodayLog() : ` - `} Times)
                      </span>
                    </li>
                    <li>
                      <h5 className="text-danger me-4">Last Log</h5>
                      <span className="text-danger">
                        {!isUndefined(myData) && myData?.user?.last_login_at
                          ? moment(myData?.user?.last_login_at).format(
                              "DD MMMM, YYYY, hh:mm:ss"
                            )
                          : ` - `}
                      </span>
                    </li>
                  </ul>
                  <div className="social-icons">
                    <Link className="facebook text-center" to={"#"}>
                      <span>
                        <i className="fa fa-facebook"></i>
                      </span>
                    </Link>
                    <Link className="twitter text-center" to={"#"}>
                      <span>
                        <i className="fa fa-twitter"></i>
                      </span>
                    </Link>
                    <Link className="youtube text-center" to={"#"}>
                      <span>
                        <i className="fa fa-youtube"></i>
                      </span>
                    </Link>
                    <Link className="googlePlus text-center" to={"#"}>
                      <span>
                        <i className="fa fa-google"></i>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-6 col-lg-6 col-md-6">
              <div className="card acc_balance">
                <div className="card-header">
                  <h4 className="card-title">Wallet</h4>
                </div>
                <div className="card-body">
                  <span>Available USD</span>
                  <h3>
                    {!isWalletBalanceRefetching &&
                    !isEmpty(walletBalances) &&
                    !isEmpty(caladexBalances) ? (
                      formatDegits(
                        getTotalUsdBalance(caladexBalances)?.totalBalance,
                        TOKEN_DIGITS
                      )
                    ) : isCaladexFetched ? (
                      " - "
                    ) : (
                      <CircularProgress
                        sx={{
                          width: "1rem !important",
                          height: "1rem !important",
                        }}
                      />
                    )}{" "}
                    USD
                  </h3>

                  <div className="d-flex justify-content-between my-3 text-primary">
                    <div>
                      <p className="mb-1">Caladex Balance</p>
                      <h4>
                        {!isEmpty(caladexBalances) ? (
                          formatDegits(
                            getTotalUsdBalance(caladexBalances)?.caladexBalance,
                            TOKEN_DIGITS
                          )
                        ) : isCaladexFetched ? (
                          " - "
                        ) : (
                          <CircularProgress
                            sx={{
                              width: "1rem !important",
                              height: "1rem !important",
                            }}
                          />
                        )}{" "}
                        USD
                      </h4>
                    </div>
                    <div>
                      <p className="mb-1">Wallet Balance</p>
                      <h4>
                        {!isWalletBalanceRefetching &&
                        !isEmpty(walletBalances) ? (
                          formatDegits(
                            getTotalUsdBalance(caladexBalances)?.walletBalance,
                            TOKEN_DIGITS
                          )
                        ) : (
                          <CircularProgress
                            sx={{
                              width: "1rem !important",
                              height: "1rem !important",
                            }}
                          />
                        )}{" "}
                        USD
                      </h4>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between my-3 text-primary">
                    <div>
                      <p className="mb-1">Buy this month</p>
                      <h4 className="text-success">
                        {!isUndefined(data) && !isEmpty(tokenPriceList) ? (
                          formatDegits(
                            getMonthlyTradeUsdValue()?.buyUsdValue,
                            TOKEN_DIGITS
                          )
                        ) : (
                          <CircularProgress
                            sx={{
                              width: "1rem !important",
                              height: "1rem !important",
                            }}
                          />
                        )}{" "}
                        USD
                      </h4>
                    </div>
                    <div>
                      <p className="mb-1">Sell this month</p>
                      <h4 className="text-danger">
                        {!isUndefined(data) && !isEmpty(tokenPriceList) ? (
                          formatDegits(
                            getMonthlyTradeUsdValue()?.sellUsdValue,
                            TOKEN_DIGITS
                          )
                        ) : (
                          <CircularProgress
                            sx={{
                              width: "1rem !important",
                              height: "1rem !important",
                            }}
                          />
                        )}{" "}
                        USD
                      </h4>
                    </div>
                  </div>

                  <div className="btn-group mb-3">
                    <Link
                      className="btn btn-success"
                      to={`/exchange/polygon/cax_dai`}
                    >
                      Buy
                    </Link>
                    <Link
                      className="btn btn-danger"
                      to={`/exchange/polygon/cax_dai`}
                    >
                      Sell
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-12">
              <div className="card">
                <div className="card-header bg-primary fw-bold">
                  <h4 className="card-title">Token Balances</h4>
                </div>
                <div className="card-body">
                  <div className="balance-table">
                    <div className="table-responsive">
                      <table className="table table-striped mb-0 table-responsive-sm">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th className="farm-logo-td text-primary">Token</th>
                            <th>Wallet Balance</th>
                            <th>Caladex Balance</th>
                            <th>Total Equity</th>
                            <th>Value in USD</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!isEmpty(caladexBalances) ? (
                            [...caladexBalances]?.map((balance, index) => (
                              <tr key={`account-overview-balance-${index}`}>
                                <td className="text-white-50">#{index + 1}</td>
                                <td className="farm-logo-td text-white-50">
                                  <i className="cc">
                                    <ImageContainer
                                      src={`${BACKEND_FILE_URL}/${balance?.token_id?.logo_url}`}
                                      style={{ width: "30px" }}
                                    />
                                  </i>{" "}
                                  {balance.token_id.symbol}
                                </td>
                                <td className="text-white-50">
                                  {!isWalletBalanceRefetching &&
                                  !isEmpty(walletBalances) ? (
                                    formatDegits(
                                      getTokenBalance(
                                        balance.token_id._id,
                                        walletBalances
                                      ),
                                      2
                                    )
                                  ) : (
                                    <CircularProgress
                                      sx={{
                                        width: "1rem !important",
                                        height: "1rem !important",
                                      }}
                                    />
                                  )}{" "}
                                  {balance?.token_id?.symbol}
                                </td>
                                <td className="text-white-50">
                                  {formatDegits(
                                    +balance?.caladex_balance +
                                      +balance?.order_balance,
                                    TOKEN_DIGITS
                                  )}{" "}
                                  {balance?.token_id?.symbol} (
                                  {
                                    CHAIN_LIST?.find(
                                      (chain) =>
                                        chain.chainId ===
                                        balance?.token_id?.chain_id
                                    )?.chainName
                                  }
                                  )
                                </td>
                                <td className="text-white-50">
                                  {!isWalletBalanceRefetching &&
                                  !isEmpty(walletBalances) ? (
                                    formatDegits(
                                      +balance?.caladex_balance +
                                        +balance?.order_balance +
                                        getTokenBalance(
                                          balance.token_id._id,
                                          walletBalances
                                        ),
                                      TOKEN_DIGITS
                                    )
                                  ) : (
                                    <CircularProgress
                                      sx={{
                                        width: "1rem !important",
                                        height: "1rem !important",
                                      }}
                                    />
                                  )}{" "}
                                  {balance?.token_id?.symbol}
                                </td>
                                <td className="text-white-50">
                                  $
                                  {!isWalletBalanceRefetching &&
                                  !isEmpty(walletBalances) &&
                                  !isEmpty(caladexBalances) &&
                                  !isEmpty(tokenPriceList) ? (
                                    formatDegits(
                                      _getTokenUsdValue(balance)?.totalBalance,
                                      TOKEN_DIGITS
                                    )
                                  ) : isCaladexFetched ? (
                                    " - "
                                  ) : (
                                    <CircularProgress
                                      sx={{
                                        width: "1rem !important",
                                        height: "1rem !important",
                                      }}
                                    />
                                  )}
                                </td>
                                <td>
                                  <Link
                                    to={`/account-deposit/${balance.token_id._id}`}
                                    className="btn btn-primary text-white"
                                  >
                                    <i className="la la-plus"></i>
                                  </Link>
                                  <Link
                                    to={`/account-withdraw/${balance.token_id._id}`}
                                    className="btn btn-danger text-white"
                                  >
                                    <i className="la la-minus"></i>
                                  </Link>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td className="text-success" colSpan={7}>
                                <div className="d-flex justify-content-center">
                                  <CircularProgress />
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-xl-12"
              style={{ height: "500px", overflow: "auto" }}
            >
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Transactions History</h4>
                </div>
                <div className="card-body">
                  <div className="transaction-table">
                    <div className="table-responsive">
                      <table className="table table-striped mb-0 table-responsive-sm">
                        <thead>
                          <tr>
                            <th className="farm-logo-td text-primary">Type</th>
                            <th>Transaction ID</th>
                            <th>Time</th>
                            <th>Token</th>
                            <th>Wallet Address</th>
                            <th>Amount</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!isUndefined(data) ? (
                            data?.transactions.map((transaction, index) => (
                              <tr key={`account-transaction-${index}`}>
                                <td className="text-white fw-bold farm-logo-td">
                                  {transaction?.type}
                                </td>
                                <td className="text-white fw-bold">
                                  #
                                  {!!transaction.tx_hash
                                    ? humanReadableAccount(transaction.tx_hash)
                                    : `-`}
                                </td>
                                <td className="text-white-50">
                                  {moment(transaction.created_at).format(
                                    "D MMMM, YYYY, HH:mm:ss"
                                  )}
                                </td>
                                <td className="text-white-50">
                                  {transaction.token_id?.symbol} (
                                  {
                                    CHAIN_LIST.find(
                                      (chain) =>
                                        chain.chainId ==
                                        transaction.token_id?.chain_id
                                    )?.chainName
                                  }
                                  )
                                </td>
                                <td className="text-white-50">
                                  {humanReadableAccount(
                                    transaction.wallet_addr
                                  )}
                                </td>
                                <td className="text-white-50">
                                  {transaction.amount}{" "}
                                </td>
                                <td
                                  className={
                                    transaction.status == "verified"
                                      ? `text-success`
                                      : `text-warning`
                                  }
                                >
                                  {transaction.status}
                                  <i
                                    className={
                                      transaction.status == "verified"
                                        ? "mdi mdi-check fs-20"
                                        : "mdi mdi-clock fs-20"
                                    }
                                  ></i>
                                </td>
                                <td>
                                  {transaction.status == "verified" ? (
                                    <Link
                                      target={`_blank`}
                                      to={{
                                        pathname: `${
                                          CHAIN_LIST.find(
                                            (chain) =>
                                              chain.chainId ==
                                              transaction?.chain_id
                                          )?.blockScanUrl
                                        }/tx/${transaction?.tx_hash}`,
                                      }}
                                    >
                                      <i className="la la-lg la-external-link text-primary cursor-pointer"></i>
                                    </Link>
                                  ) : (
                                    <i
                                      className="la la-lg la-trash text-danger cursor-pointer"
                                      onClick={() => {
                                        handleRemoveWithdrawal(
                                          transaction?._id
                                        );
                                      }}
                                    ></i>
                                  )}
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={8} className="mx-auto">
                                <div className="d-flex justify-content-center">
                                  <CircularProgress />
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer2 />
    </>
  );
}

export default AccountOverview;
