import React, { useContext } from "react";
import Footer2 from "../layout/footer2";
import Header2 from "../layout/header2";
import Axios from "axios";
import { useHistory, useParams } from "react-router-dom";
import {
  BACKEND_API_URL,
  HTTP_SUCCESS_CODE,
  HTTP_SUCCESS_STATUS,
  TOKEN_POLLING_INTERVAL,
  USER_STATUS,
} from "../../shared/constants";
import { useQuery } from "react-query";
import { LinearProgress } from "@mui/material";
import { AuthContext } from "../../contexts/AuthContext";
import { setRefreshToken } from "../../shared/helpers";
import { toast } from "react-hot-toast";
import * as Yup from "yup";

function EmailVerify() {
  const { id, token } = useParams();

  const {
    setCurrentUser,
    setAuthProcessing,
    setAuthenticated,
    currentUser,
    setAuthToken,
  } = useContext(AuthContext);

  const history = useHistory();

  const [error, setError] = React.useState("");

  const fetchData = async ({ queryKey }) => {
    const [_, _id, _token] = queryKey;

    if (_token === undefined) {
      if (!currentUser?.id || currentUser?.id !== id) {
        // setAuthProcessing(0);
        // setAuthenticated(false);
        // history.push('/signin');
      }
      try {
        const response = await Axios.post(
          `${BACKEND_API_URL}/auth/verified-email/${_id}`,
          {}
        );
        if (response?.status !== HTTP_SUCCESS_STATUS) {
          throw new Error(response?.data.message);
        }

        setAuthProcessing(0);
        const { user, token, refreshToken } = response?.data?.data;

        setCurrentUser({ ...user, id: user._id });
        setAuthToken(token);
        setRefreshToken(refreshToken);
        setAuthenticated(true);
        history.push("/exchange/polygon/cax_dai");
      } catch (e) {
        if (e?.response?.data?.message) {
          console.log(
            "verify-email-Page@verified-email-error:",
            e?.response?.data?.message
          );
          setError(e?.response?.data?.message);
        } else {
          console.error("verify-email-Page@verified-email-error:", e.message);
          setError(e.message);
        }
      }
      return false;
    }

    // if have token to verify
    const response = await Axios.get(
      `${BACKEND_API_URL}/auth/verify-email/${_id}/${_token}`,
      {}
    );

    if (
      response?.status === HTTP_SUCCESS_STATUS &&
      response?.data?.status === HTTP_SUCCESS_CODE
    ) {
      const { user, token, refreshToken } = response?.data?.data;
      setCurrentUser({ ...user, id: user._id });
      setAuthToken(token);
      setRefreshToken(refreshToken);
      setAuthenticated(true);
      // check if approved, go to exchange page directly
      if (user?.status === USER_STATUS.APPROVED) {
        history.push("/exchange/polygon/cax_dai");
        return false;
      }
    }
    return false;
  };

  const { isLoading } = useQuery(["email-verify", id, token], fetchData, {
    refetchOnWindowFocus: false,
    //enabled: false, // disable this query from automaticaslly running
    // staleTime: token === undefined ? 4000 : Infinity,
    refetchInterval: TOKEN_POLLING_INTERVAL,
    retry: true,
  });

  const handleResendEmail = async () => {
    if (
      currentUser?.status === USER_STATUS.VERIFIED ||
      currentUser?.status === USER_STATUS.VERIFYING
    ) {
      toast.error("Please login or your account is verified already.");
      return false;
    }

    const payloadSchema = Yup.object().shape({
      id: Yup.string().min(10).max(255).required(),
    });

    let validationResult = "";

    await payloadSchema.validate({ id }).catch((err) => {
      validationResult = err.message;
      return false;
    });

    if (validationResult) {
      console.log(validationResult);
      toast.error("Invalid parameter detected.");
      return false;
    }

    const _toast = toast.loading("Resending Email...");

    let response = await Axios.post(
      `${BACKEND_API_URL}/auth/resend-email/${id}`,
      {}
    );

    if (response?.status !== HTTP_SUCCESS_STATUS) {
      toast.dismiss(_toast);
      toast.error("Unfortunately Email Not Sent!");
      throw new Error("Resending email failed.");
    }

    toast.dismiss(_toast);
    toast.success("Email Successfully Sent!");

    return false;
  };

  return (
    <>
      <Header2 />
      {isLoading && <LinearProgress color="info" />}
      <div className="verification section-padding">
        <div className="container h-100">
          <div className="row justify-content-center h-100 align-items-center">
            <div className="col-xl-5 col-md-6">
              <div className="auth-form card">
                <div className="card-body">
                  {error && (
                    <p className="text-danger my-3 text-center">{error}</p>
                  )}
                  <form action="verify-step-4.html" className="identity-upload">
                    <div className="identity-content">
                      <span className="icon">
                        <i className="fa fa-shield"></i>
                      </span>
                      <h4>Please verify your Email!</h4>
                      <p>We have sent message to your mailbox.</p>
                    </div>

                    <div className="upload-loading text-center mb-3">
                      <i className="fa fa-spinner fa-spin fa-3x fa-fw"></i>
                    </div>
                    <div className="text-center">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleResendEmail}
                      >
                        Resend Email
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer2 />
    </>
  );
}

export default EmailVerify;
