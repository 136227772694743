import React, { useEffect, useState } from "react";
import { getRefreshToken } from "../shared/helpers";
import { useLocation, useHistory } from "react-router-dom";
import {
  BACKEND_API_URL,
  HTTP_SUCCESS_CODE,
  HTTP_SUCCESS_STATUS,
} from "../shared/constants";
import Axios from "axios";
import { useQuery } from "react-query";
import jwt_decode from "jwt-decode";
import {
  useTargetTokenList,
  useTokenPairList,
  useTokenPriceList,
  useTokenLandingList,
} from "../state/hooks";

export const AuthContext = React.createContext({
  isAuthProcessing: 0,
  isAuthenticated: false,
  currentUser: {},
});

export const AuthProvider = ({ children }) => {
  const [isAuthProcessing, setAuthProcessing] = useState(0);
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [currentUser, setCurrentUser] = useState({});
  const [authToken, setAuthToken] = useState("");
  const [currentIp, setCurrentIp] = useState("");
  const { pathname } = useLocation();
  const history = useHistory();

  const {
    isRefetching: isTokenPairListRefetching,
    isSuccess: isTokenPairListSuccess,
    isError: isTokenPairListError,
    refetch: tokenPairRefresh,
  } = useTokenPairList();
  const {
    isRefetching: isTokenLandingListRefetching,
    isSuccess: isTokenLandingListSuccess,
    isError: isTokenLandingListError,
    refetch: tokenLandingRefresh,
  } = useTokenLandingList();
  const {
    isRefetching: isTargetTokenListRefetching,
    isSuccess: isTargetTokenListSuccess,
    isError: isTargetTokenListError,
    refetch: targetTokenRefresh,
  } = useTargetTokenList();
  const {
    isRefetching: isTokenPriceListRefetching,
    isSuccess: isTokenPriceListSuccess,
    isError: isTokenPriceListError,
    refetch: tokenPriceRefresh,
  } = useTokenPriceList();

  const fetchData = async () => {
    const refreshToken = getRefreshToken();

    if (!refreshToken) {
      if (isAuthenticated) {
        setAuthenticated(false);
      }

      return false;
    }

    try {
      const response = await Axios.post(
        `${BACKEND_API_URL}/auth/refresh-token`,
        { ip_address: currentIp, refreshToken }
      );

      if (
        response?.status === HTTP_SUCCESS_STATUS &&
        response?.data?.status === HTTP_SUCCESS_CODE
      ) {
        const { token } = response?.data?.data;
        const { id, email, name } = await jwt_decode(token);

        setAuthToken(token);
        setCurrentUser({ id, email, name });
        setAuthenticated(true);
      }
    } catch (e) {
      setAuthenticated(false);
      // setCurrentUser({})
      // setRefreshToken("")
      // history.push('/signin')
    }

    return false;
  };

  const { isLoading, isError, data, refetch, error, isSuccess } = useQuery(
    "refresh-token",
    fetchData,
    {
      refetchOnWindowFocus: false,
      //enabled: false, // disable this query from automaticaslly running
      staleTime: 10000,
      refetchInterval: 50000,
      retry: true,
      retryDelay: 20000,
    }
  );

  useEffect(() => {
    const fetchLocation = async () => {
      await Axios.get("https://geolocation-db.com/json/").then((res) => {
        setCurrentIp(res.data.IPv4);
      });
    };

    fetchLocation();
  }, []);

  useEffect(() => {
    if (isAuthProcessing) {
      history.push(`otp-${isAuthProcessing}`);
    }
  }, [pathname]);

  return (
    <AuthContext.Provider
      value={{
        isAuthProcessing,
        setAuthProcessing,
        isAuthenticated,
        setAuthenticated,
        currentUser,
        setCurrentUser,
        currentIp,
        setCurrentIp,
        authToken,
        setAuthToken,
        isTokenPairListRefetching,
        isTokenPairListSuccess,
        tokenPairRefresh,
        isTokenLandingListRefetching,
        isTokenLandingListSuccess,
        tokenLandingRefresh,
        isTargetTokenListRefetching,
        isTargetTokenListSuccess,
        targetTokenRefresh,
        isTokenPriceListRefetching,
        tokenPriceRefresh,
        refreshTokenRefetch: refetch,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
